import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { getOnBoardingUser, getUser, removeOnBoardingUser } from 'utils/UserDetails';
import RoleProvider from 'context/Role';
import { isEmpty } from 'lodash';

const PublicRoute = ({ children }) => {
  const { pathname } = useLocation();

  if (pathname === '/') {
    return <Navigate to="/login" replace />;
  }
  if (pathname === '/login') removeOnBoardingUser();
  const onboard = getOnBoardingUser();

  const user = getUser();
  if (onboard && !user) {
    return <Navigate to="/onboarding" replace />;
  }

  if (!isEmpty(user)) {
    return <Navigate to="/dashboard" replace />;
  }
  return <RoleProvider>{children}</RoleProvider>;
};

export default PublicRoute;
