import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const propertyInfoSlice = createSlice({
  name: 'propertyInfo',
  initialState,
  reducers: {
    updateProperty: (state, action) => {
      return (state = action.payload);
    }
  }
});

// Action creators are generated for each case reducer function
export const { updateProperty } = propertyInfoSlice.actions;

export default propertyInfoSlice.reducer;
