import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  open: false
};

export const sidebardSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    toggle: (state, action) => {
      return (state = action.payload);
    }
  }
});

// Action creators are generated for each case reducer function
export const { toggle } = sidebardSlice.actions;

export default sidebardSlice.reducer;
