import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const applicantSummary = createSlice({
  name: 'applicantSummary',
  initialState,
  reducers: {
    updateApplicantSummary: (state, action) => {
      return (state = action.payload);
    }
  }
});

export const { updateApplicantSummary } = applicantSummary.actions;

export default applicantSummary.reducer;
