import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

export const applicants = createSlice({
  name: 'applicants',
  initialState,
  reducers: {
    updateApplicants: (state, action) => {
      return (state = action.payload);
    }
  }
});

export const { updateApplicants } = applicants.actions;

export default applicants.reducer;
