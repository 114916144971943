import React, { Fragment, useContext, useRef, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import Navigation from 'components/NavigationBar';
import Sidebar from 'components/Sidebar';
import { getOnBoardingUser, getUser } from 'utils/UserDetails';
import RoleProvider, { RoleContext } from 'context/Role';
import UserDetailProvider, { UserDetailsContext } from 'context/UserDetail';
import { isTabBrakePoint } from 'utils/responsiveBrakePoints';
import {
  AGENT_KEY,
  APPROVED_KEY,
  INVESTOR_KEY,
  NOT_REQUESTED,
  PENDING_KEY,
  REJECTED_KEY
} from 'constant';
import Profile from 'pages/Profile';
import { INVESTOR_ROUTES } from './Helper';
import { toggle } from 'store/Slices/Sidebar';
import UserFilterProvider from '../pages/SuperAdmin/User/context/Filter';
import OpportunityContentsProvider from 'pages/Opportunity/components/CreateOpportunity/context';

const PrivateRoute = ({ children, flag, isNotHeaderSidebar }) => {
  const user = getUser();
  const onBoarding = getOnBoardingUser();

  if (flag && onBoarding && isEmpty(user)) {
    return children;
  }

  if (onBoarding && isEmpty(user)) {
    return <Navigate to={'/onboarding'} replace />;
  }

  if (flag && !onBoarding) {
    return <Navigate to={'/dashboard'} replace />;
  }

  if (isEmpty(user)) {
    return <Navigate to={'/'} replace />;
  }

  return (
    <UserDetailProvider>
      <RoleProvider>
        <UserFilterProvider>
          <OpportunityContentsProvider>
            <Content isNotHeaderSidebar={isNotHeaderSidebar}>{children}</Content>
          </OpportunityContentsProvider>
        </UserFilterProvider>
      </RoleProvider>
    </UserDetailProvider>
  );
};
export default PrivateRoute;

const Content = ({ isNotHeaderSidebar, children }) => {
  const { open } = useSelector((state) => state.sidebar);
  const dispatch = useDispatch();
  const location = useLocation();
  const { roleTitle } = useContext(RoleContext);
  const userData = useContext(UserDetailsContext);
  const isTab = useMediaQuery({ query: `(${isTabBrakePoint})` });

  const splittiedURL = location.pathname.split('/');

  if (isNotHeaderSidebar) {
    return children;
  }

  if (!isEmpty(userData) && !userData.isPrivacyPolicy) {
    return <Navigate to={'/terms-policies'} state={{ path: location.pathname }} replace />;
  }

  const onClickMenu = (flag, e) => {
    if (typeof flag === 'boolean') {
      dispatch(toggle({ open: flag }));
    }
  };

  if (roleTitle === INVESTOR_KEY && INVESTOR_ROUTES.includes(splittiedURL[1])) {
    return (
      <SemanticUI onClickMenu={onClickMenu} openSidebar={open} isTab={isTab}>
        {children}
      </SemanticUI>
    );
  }

  const { requestStatus, licensedFsra, approvedStatus } = userData;

  const flag =
    requestStatus === NOT_REQUESTED ||
    requestStatus === PENDING_KEY ||
    requestStatus === REJECTED_KEY;

  if (
    roleTitle === AGENT_KEY &&
    approvedStatus === APPROVED_KEY &&
    flag &&
    location.pathname === '/dashboard'
  ) {
    return (
      <SemanticUI onClickMenu={onClickMenu} openSidebar={open} isTab={isTab}>
        <Profile />
      </SemanticUI>
    );
  }

  if (
    roleTitle === INVESTOR_KEY &&
    !licensedFsra &&
    flag &&
    approvedStatus === APPROVED_KEY &&
    location.pathname === '/dashboard'
  ) {
    return (
      <SemanticUI onClickMenu={onClickMenu} openSidebar={open} isTab={isTab}>
        <Profile />
      </SemanticUI>
    );
  }

  return (
    <SemanticUI onClickMenu={onClickMenu} openSidebar={open} isTab={isTab}>
      {children}
    </SemanticUI>
  );
};

const SemanticUI = ({ children, onClickMenu, openSidebar, isTab }) => {
  return (
    <Fragment>
      <div>
        <Navigation onClickMenu={onClickMenu} />
      </div>
      <Sidebar isOpen={openSidebar} onClickMenu={onClickMenu} />
      <div
        id="main"
        style={{
          marginLeft: isTab ? '0px' : '48px'
        }}>
        <div className="contain-height">{children}</div>
      </div>
    </Fragment>
  );
};
