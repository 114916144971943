import React, { Suspense } from 'react';
import PageLoader from 'components/PageLoader';
import PrivateRoute from './ProtectedRoute';
import PublicRoute from './PublicRoute';
import EmailVerify from 'pages/Profile/common/EmailVerify';
import PersonalInfo from 'pages/Profile/components/Investor/components/PersonalInfo';
import LendingCriteria from 'pages/Profile/common/LendingCriteria';
import Accounts from 'pages/Profile/components/Investor/components/Accounts';
import Esign from 'pages/Profile/common/Esign';
import BusinessInfo from 'pages/Profile/components/MortgageAdmin/components/BusinessInfo';
import PersonalDetails from 'pages/Profile/components/Broker/components/PersonalDetails';
import BrokerDetails from 'pages/Profile/components/Broker/components/BrokerageDetails/BrokerDetails';
import MortgageAdminCard from 'pages/Profile/components/Investor/components/MortgageAdmin';
import InvestorsOfMa from 'pages/Profile/components/MortgageAdmin/components/InvestorsOfMa';
import FinalApproval from 'pages/Profile/common/FinalApproval';
import ApprovePersonalInfo from 'pages/Profile/common/ApprovePersonalInfo';
import BrokerageDetails from 'pages/Profile/components/PrincipalBroker/components/BrokerageDetails';
import BrokerageDetailsContainer from 'pages/Profile/components/Broker/components/BrokerageDetails';
import FilogixIntegration from 'pages/Profile/common/FilogixIntegration';

const PrivateSuspense = ({ children, ...rest }) => {
  return (
    <Suspense fallback={<PageLoader fullPageHeight />}>
      <PrivateRoute {...rest}>{children}</PrivateRoute>
    </Suspense>
  );
};

const PublicSuspense = ({ children, ...rest }) => {
  return (
    <Suspense fallback={<PageLoader fullPageHeight />}>
      <PublicRoute {...rest}>{children}</PublicRoute>
    </Suspense>
  );
};

const INVESTOR_ROUTES = ['find-opportunity', 'opportunity-detail', 'find-mortgage-admin'];

const commonRoute = [
  {
    path: 'email-verify',
    element: <EmailVerify />
  },
  {
    path: 'e-sign',
    element: <Esign />
  }
];

const lendingCriteriaRoute = {
  path: 'lending-criteria',
  element: <LendingCriteria />
};

const profileChildrens = [
  ...commonRoute,
  {
    path: 'personal-info',
    element: <PersonalInfo />
  },
  {
    path: 'accounts',
    element: <Accounts />
  },
  lendingCriteriaRoute,
  {
    path: 'business-info',
    element: <BusinessInfo />
  },
  {
    path: 'personal-details',
    element: <PersonalDetails />
  },
  {
    path: 'brokerage',
    element: <BrokerageDetailsContainer />
  },
  {
    path: 'brokerage-info',
    element: <PersonalDetails />
  },
  {
    path: 'mortgage-admin',
    element: <MortgageAdminCard />
  },
  {
    path: 'investors',
    element: <InvestorsOfMa />
  },
  {
    path: 'final-approval',
    element: <FinalApproval />
  },
  {
    path: 'personal-information',
    element: <ApprovePersonalInfo />
  },
  {
    path: 'brokerage-details',
    element: <BrokerageDetails />
  },
  {
    path: 'filogix-integration',
    element: <FilogixIntegration />
  }
];

export { PrivateSuspense, PublicSuspense, INVESTOR_ROUTES, profileChildrens };
