const setUserData = (data) => {
  localStorage.setItem('user', JSON.stringify(data));
};

const getUser = () => {
  return JSON.parse(localStorage.getItem('user')) || {};
};

const setOnBoardingData = (data) => {
  localStorage.setItem('onBoarding', JSON.stringify(data));
};

const getOnBoardingUser = () => {
  return JSON.parse(localStorage.getItem('onBoarding'));
};

const removeOnBoardingUser = () => {
  localStorage.removeItem('onBoarding');
};

const setRoleId = (id) => {
  localStorage.setItem('roleId', `${id}`);
};

const getRoleId = () => {
  return localStorage.getItem('roleId');
};

const setAuthMode = (mode) => {
  localStorage.setItem('authMode', mode);
};

const getAuthMode = (mode) => {
  return localStorage.getItem('authMode');
};

const getToken = () => {
  return localStorage.getItem('token');
};

const setToken = (token) => {
  localStorage.setItem('token', token);
};

const onLogOut = () => {
  localStorage.clear();
};

const setPersonalInfo = (data) => {
  localStorage.setItem('personal_data', JSON.stringify(data));
};

const removePersonalInfo = () => {
  localStorage.removeItem('personal_data');
};

const getPersonalInfo = () => {
  return JSON.parse(localStorage.getItem('personal_data'));
};

export {
  setUserData,
  setOnBoardingData,
  getOnBoardingUser,
  getUser,
  removeOnBoardingUser,
  setRoleId,
  getRoleId,
  getToken,
  setToken,
  onLogOut,
  setAuthMode,
  getAuthMode,
  getPersonalInfo,
  setPersonalInfo,
  removePersonalInfo
};
